<template>
    <div class="q-pa-md">
        <q-table
        title="Дела"
        :data="data"
        :columns="columns"
        row-key="id"
        no-data-label="Нет данных"
        no-results-label="Нет данных"
        :rows-per-page-options="[20]"
        table-header-class="bg-grey-2"
        >
            <template v-slot:top-right>
                <q-btn unelevated no-caps :color="drawer ? 'grey-4' : 'grey-1'" text-color="grey-8" icon="search" label="Поиск" @click="drawer =! drawer"/>
                <q-btn unelevated no-caps color="grey-1" text-color="green-9" icon="add" label="Создать" @click.stop="onCreate"/>
            </template>
             <template v-if="drawer" v-slot:top-row>
                <q-tr>
                <q-td colspan="100%">
                    Тут будет поиск
                </q-td>
                </q-tr>
            </template>
            <template v-slot:header-cell="props">
                <q-th :props="props">
                    <strong>{{ props.col.label }}</strong>
                </q-th>
            </template>
            <template v-slot:body="props">
                <q-tr :props="props" style="cursor:pointer" @click="onRowClick(props.key)">
                    <q-td key="number" :props="props" >
                        {{props.row.number}}
                    </q-td>
                    <q-td key="region" :props="props">
                        {{props.row.region.label}}
                    </q-td>
                    <q-td key="type" :props="props">
                        <q-badge outline :color="typeColor(props.row.type.id)" :label="props.row.type.label" style="padding:4px 8px" class="text-capitalize"/>
                    </q-td>
                    <q-td key="dueDate" :props="props">
                        {{props.row.dueDate}}
                    </q-td>
                    <q-td key="cost" :props="props">
                        {{cost(props.row)}}
                    </q-td>
                    <q-td key="paymentStatus" :props="props">
                        <q-badge outline :color="paymentStatusColor(props.row.paymentStatus.id)" :label="props.row.paymentStatus.label" style="padding:4px 8px" class="text-capitalize"/>
                    </q-td>
                    
                    <q-td key="status" :props="props">
                        <q-badge :color="statusColor" :label="props.row.status.label" style="padding:5px 10px" class="text-uppercase"/>
                        <!-- <q-badge outline color="primary" :label="props.row.status" /> -->
                        <!-- <q-btn-dropdown outline rounded size="sm" color="primary" :label="props.row.status">
                            <q-list>
                                <q-item clickable v-close-popup @click="onItemClick">
                                <q-item-section>
                                    <q-item-label>Новое</q-item-label>
                                </q-item-section>
                                </q-item>

                                <q-item clickable v-close-popup @click="onItemClick">
                                <q-item-section>
                                    <q-item-label>В работе</q-item-label>
                                </q-item-section>
                                </q-item>

                                <q-item clickable v-close-popup @click="onItemClick">
                                <q-item-section>
                                    <q-item-label>Отменен</q-item-label>
                                </q-item-section>
                                </q-item>
                            </q-list> -->
                        <!-- </q-btn-dropdown> -->
                        
                    </q-td>
                </q-tr>
            </template>
        </q-table>
       
    </div>
</template>

<script>

export default {
    created(){
        this.$store.dispatch('cases/fetchData')
    },
    data () {
        return {
            editStatus: false,
            columns: [
                { name: 'number', required: true, label: 'Номер дела', align: 'left', field: 'number', sortable: true },
                { name: 'region', align: 'left', label: 'Регион', field: 'region', sortable: true },
                { name: 'type', align: 'left', label: 'Тип дела', field: 'type', sortable: true },
                { name: 'dueDate',  align: 'left', label: 'Дата до', field: 'dueDate', sortable: true},
                { name: 'cost',  align: 'left', label: 'Цена', field: 'cost', sortable: true},
                { name: 'paymentStatus',  align: 'left', label: 'Оплачено', field: 'paymentStatus', sortable: true},
                { name: 'status',  align: 'left', label: 'Статус', field: 'status', sortable: true},
            ],
            drawer:false
        }
    },
    computed:{
        data(){
            return this.$store.state.cases.data
        },
        typeColor(){
            return val => val === 1 ? 'primary' : 'red'
        },
        paymentStatusColor(){
            return val => val === 1 ? 'red-4': 'secondary'
        },
        statusColor(){    
            return 'bg-grey-10'
        },
        cost(){
            return val => val.type.id === 1 ? val.claimCost : val.caseCost
        }
    },
    methods: {
        onRowClick(row){
            this.$router.push('/cases/'+row)
        },
        onCreate(){
            this.$store.commit('cases/SET_CREATE_ON_VIOLATION',null)
            this.$router.push('/cases/create')
        }
    },
    
}
</script>

<style scoped>
    /* td:first-child{
        background-color: #f5f5dc
    } */
</style>