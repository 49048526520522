<template>
    <div>
        <table-cases/>
    </div>
</template>

<script>
import TableCases from '@/components/cases/table.vue'
export default {
  components: { TableCases },
    data () {
        return {
           
        }
    }
    
}
</script>